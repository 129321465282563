import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {KeycloakService} from "./keycloak.service";
import {Observable} from "rxjs";
import {BillingSummary} from "./models";

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {
  }

  getBillingSummary(): Observable<BillingSummary> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());
    console.log("getMonthlyBillingsService");

    return this.http.get<BillingSummary>(`${this.apiUrl}/employees/billing/summary`, {headers});

  }
}
