import {Injectable} from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {KeycloakService} from "./keycloak.service";
import {Observable} from "rxjs";
import {Billing, MonthlyBilling} from "./models";

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {
  }

  getMonthlyBillings(): Observable<MonthlyBilling> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());
    console.log("getMonthlyBillingsService");
    return this.http.get<MonthlyBilling>(`${this.apiUrl}/delivery/billings`, {headers});

  }

  updateBilling(billing: Billing) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());
    console.log("patchMonthlyBillingsService");
    let id = billing.id;
    return this.http.patch<Billing>(`${this.apiUrl}/delivery/billings/${id}`, billing, {headers});
  }

  downloadInvoice(billing_id: string):  Observable<Blob> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());
    console.log("getInvoiceService");
    // Setup log namespace query parameter
    let params = new HttpParams().set('id', billing_id);
    return this.http.get(`${this.apiUrl}/invoice/download`, { headers, params, responseType: 'blob'});
  }
}
