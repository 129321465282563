<!-- table.component.html -->
<table mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
    </ng-container>

    <!-- Project Column -->
    <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef> Project </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.project}} </mat-cell>
    </ng-container>

    <!-- Manager Column -->
    <ng-container matColumnDef="manager">
        <mat-header-cell *matHeaderCellDef> Manager </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.manager}} </mat-cell>
    </ng-container>

    <!-- Currency Column -->
    <ng-container matColumnDef="currency">
        <mat-header-cell *matHeaderCellDef> Currency </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.currency}} </mat-cell>
    </ng-container>

    <!-- Hourly Rate Column -->
    <ng-container matColumnDef="billing">
        <mat-header-cell *matHeaderCellDef> Billing </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.billing}} </mat-cell>
    </ng-container>

    <!-- Hours Column -->
    <ng-container matColumnDef="invoice_sum">
        <mat-header-cell *matHeaderCellDef> Invoice Sum </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.invoice_sum}} </mat-cell>
    </ng-container>

    <!-- Invoice Column -->
    <ng-container matColumnDef="invoice">
        <mat-header-cell *matHeaderCellDef> Invoice </mat-header-cell>
        <mat-cell *matCellDef="let row"> <button><mat-icon>file_open</mat-icon></button> </mat-cell>
    </ng-container>

    <!-- Sum Column -->
    <ng-container matColumnDef="accept">
        <mat-header-cell *matHeaderCellDef> Accept </mat-header-cell>
        <mat-cell *matCellDef="let row"> <mat-checkbox></mat-checkbox> </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>

<mat-paginator [pageSizeOptions]="[ 10, 15, 25, 100]"></mat-paginator>
<button mat-fab extended color="primary">
    <mat-icon>done_outline</mat-icon>
    Accept
</button>