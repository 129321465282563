<mat-toolbar color="primary">
    <span>ITbooster</span>
    <span class="fill-remaining-space"></span>
    <div *ngIf="getUsername()" class="period">
        <mat-select [(value)]="selectedPeriod" (selectionChange)="updatePeriod()">
          <mat-option *ngFor="let period of periods" [value]="period">{{ period }}</mat-option>
        </mat-select>
    </div>
    <button  *ngIf="getUsername()" mat-button [matMenuTriggerFor]="menu">
        <mat-icon>account_circle</mat-icon>
        <span>{{ getUsername() }}</span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
  </mat-toolbar>