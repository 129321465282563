import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DeliveryService} from '../delivery.service';
import {MatTableDataSource} from "@angular/material/table";
import {Billing} from "../models";
import {Observable} from "rxjs";

@Component({
  selector: 'app-delivery-billing',
  templateUrl: './delivery-billing.component.html',
  styleUrls: ['./delivery-billing.component.scss']
})
export class DeliveryBillingComponent implements AfterViewInit {
  dataSource: MatTableDataSource<Billing> = new MatTableDataSource;
  @ViewChild(MatSort) sort: MatSort = <MatSort>{};
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};
  displayedColumns: string[] = ['employee_name', 'employee_surname', 'rate', 'currency', 'total_hours', 'total_sum', 'month', 'status', 'new_status', 'save', 'invoice_path', 'download_invoice'];

  constructor(private deliveryService: DeliveryService) {
    console.log("getMonthlyBillingsComponentConstructor");
  }

  ngAfterViewInit(): void {
    console.log("getMonthlyBillingsComponentAfterViewInit");
    this.deliveryService.getMonthlyBillings().subscribe(response => {
      this.dataSource = new MatTableDataSource<Billing>(response.billings);
      console.log("getMonthlyBillingsComponent: ", response);
    });
  }

  saveStatusForBilling(billing: Billing) {
    this.deliveryService.updateBilling(billing).subscribe(response => {
      console.log("changed status: ",billing.status, "for billingID: ", billing.id);
      console.log("patchMonthlyBillingsComponent: ", response);
    } );
  }

  downloadInvoice(billing_id: string): void {
    this.deliveryService.downloadInvoice(billing_id).subscribe(data => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}

