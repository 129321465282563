import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { KeycloakService } from './keycloak.service';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private apiUrl: string = environment.apiUrl;
  private users: User[] = [];

  constructor(private http: HttpClient, private keycloakService: KeycloakService) { }

  getUsers(): Observable<{ employees: User[] }> {
    if (this.users.length > 0) {
      return of({ employees: this.users });
    }

    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    return this.http.get<{ employees: User[] }>(`${this.apiUrl}/employees/all`, { headers })
      .pipe(
        tap(response => {
          this.users = response.employees;
        })
      );
  }
}
