import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { KeycloakService } from './keycloak.service';
import { MonthlyBenefits } from './models';

export interface CreateBenefitRequest {
  amount: string;
  currency: string;
  company: string;
  month: string;
  subject: string;
}

export interface UpdateBenefitRequest {
  amount: string;
  currency: string;
  subject: string;
}

@Injectable({
  providedIn: 'root'
})
export class ItsService {
  private apiUrl: string = environment.apiUrl;
  CreateBenefitRequest: any;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) { }

  getMonthlyBenefits(month: string): Observable<MonthlyBenefits> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    const params = { month }; // Query parameters object

    return this.http.get<MonthlyBenefits>(`${this.apiUrl}/benefits`, { headers, params });
  }

  createBenefit(employeeID: number, data: CreateBenefitRequest): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    return this.http.post<any>(`${this.apiUrl}/employees/${employeeID}/benefits/`, data, { headers });
  }

  updateBenefit(employeeID: number, benefitID: number, data: UpdateBenefitRequest): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    return this.http.put<any>(`${this.apiUrl}/${employeeID}/it-support/${benefitID}`, data, { headers });
  }

  deleteBenefit(employeeID: number, benefitID: number): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    return this.http.delete<any>(`${this.apiUrl}/employees/${employeeID}/benefits/${benefitID}`, { headers });
  }
}
