import {AfterViewInit, Component} from '@angular/core';
import {SummaryService} from "../summary.service";
import {BillingSummary} from "../models";

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements AfterViewInit {
  dataSource: BillingSummary = <BillingSummary>{};

  constructor(private summaryService: SummaryService) {
    console.log("getBillingSummaryConstructor");
  }

  ngAfterViewInit(): void {
    console.log("getBillingSummaryAfterViewInit");
    this.summaryService.getBillingSummary().subscribe(response => {
      this.dataSource = response
      console.log("getBillingSummaryComponent: ", response);
    });
  }

}
