import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '../keycloak.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
  selectedPeriod: string;
  periods: string[]; // Array to hold the dropdown options

  constructor(private router: Router, private key: KeycloakService, private dataService : DataService) {
    this.periods = this.generatePeriodOptions(); // Populate the dropdown options
    this.selectedPeriod = this.getDefaultPeriod(); // Set the default selected period
    this.dataService.setMonth(this.getSelectedPeriod());
   }

  getUsername(): string {
    return this.key.getUserName();
  }

  logout(): void {
   this.key.logout();
   this.router.navigate(['/login']); // Route to the login page
  }

  generatePeriodOptions(): string[] {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // JavaScript months are zero-based
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Generate options for current, previous and next months
    const options = [];
    for (let i = -1; i <= 1; i++) {
      const monthIndex = (currentMonth + i - 1) % 12;
      const year = currentYear + Math.floor((currentMonth + i - 1) / 12);
      options.push(`${months[monthIndex]} ${year}`);
    }

    return options;
  }

  getSelectedPeriod(): string {
    const [month, year] = this.selectedPeriod.split(' ');
    const monthIndex = this.getMonthIndex(month);
    return `${year}-${this.padZero(monthIndex + 1)}`;
  }

  getMonthIndex(month: string): number {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months.indexOf(month);
  }

  padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  getDefaultPeriod(): string {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    return `${this.getMonthName(currentMonth)} ${currentYear}`;
  }

  getMonthName(monthIndex: number): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthIndex];
  }

  updatePeriod(): void {
    this.dataService.setMonth(this.getSelectedPeriod());
  }
}