import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {UsersService} from '../users.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItsService} from '../its.service';
import {Subscription} from 'rxjs';
import {DataService} from '../data.service';
import {Benefit, MonthlyEmployeeBenefit} from '../models';
import {MatDialog} from "@angular/material/dialog";


export interface UserData {
  id: number;
  name: Option;
  project: string;
  subject: string;
  amount: number;
  currency: string;
  employeeId: number;
}

interface Option {
  name: string;
  id: number;
}

export const MOCK_DATA = [
  { name: {name:'John Doe', id: 1}, project: 'Project A', currency: 'USD', amount: 30, subject: "Nvidia Geforce RTX4090" }
]

@Component({
  selector: 'app-its',
  templateUrl: './its.component.html',
  styleUrls: ['./its.component.scss'],
})

export class ItsComponent implements OnInit, AfterViewInit, OnDestroy {

  userData: UserData = <UserData>{};
  dataSubscription: Subscription | undefined;
  month: string = "";
  editingId: number = -1;
  editingFormGroup: FormGroup;
  isLoading: boolean = false;

  currencies: string[] = ['USD', 'EUR', 'GBP', 'PLN'];
  dataSource: MatTableDataSource<UserData> = new MatTableDataSource<UserData>();
  @ViewChild(MatSort) sort: MatSort = <MatSort>{};
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};
  @ViewChild('deleteConfirmDialog') deleteConfirmDialog!: TemplateRef<any>;

  displayedColumns: string[] = ['name', 'project', 'subject', 'amount', 'currency', 'actions'];

  constructor(private usersService: UsersService,
              private itsService: ItsService,
              private dataService: DataService,
              private formBuilder: FormBuilder,
              private dialog: MatDialog) {
    this.month = this.dataService.getMonth();
    this.getBenefits();
    this.filteredOptions = this.options.slice();
    this.editingFormGroup = this.formBuilder.group({
      amount: [-1, Validators.required],
      subject: ['', Validators.required],
      currency: ['', Validators.required]
    });
  }

  @ViewChild('input') input: ElementRef<HTMLInputElement> | undefined;
  myControl = new FormControl('');
  options: Option[] = [];
  filteredOptions: Option[];

  filter(): void {
    if (this.input) {
      const filterValue = this.input.nativeElement.value.toLowerCase();
      this.filteredOptions = this.options.filter(o => o.name.toLowerCase().includes(filterValue));
    }
  }

  ngOnInit(): void {
    this.usersService.getUsers().subscribe(users => {
      users.employees.forEach(user => {
        this.options.push({name: user.first_name + ' ' + user.last_name, id: user.id});
      });
    });
    this.dataSubscription = this.dataService.dataObservable.subscribe(() => {
      console.log('Data changed', this.dataService.getMonth());
      this.month = this.dataService.getMonth();
      this.getBenefits();
    });
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  displayFn(option: any): string {
    return option && option.name ? option.name : '';
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onUpdate(row: any) {
    this.itsService.updateBenefit(row.employeeId, row.id, {
      amount: row.amount.toString(),
      currency: row.currency,
      subject: row.subject,
    }).subscribe({
      next: benefits => {
        this.editingId = -1;
        this.editingFormGroup.reset();
      },
      error: error => {
        console.error('Error updating benefit:', error);
      }
    });
  }

  onCancelEdit() {
    this.editingId = -1;
  }

  onDelete(employeeId: number, benefitId: number) {
    // Show confirmation dialog
    const dialogRef = this.dialog.open(this.deleteConfirmDialog);

    dialogRef.afterClosed().subscribe(result => {
      // Only proceed if user confirmed (clicked Delete)
      if (result === true) {
        this.dataSource.data = this.dataSource.data;
        this.itsService.deleteBenefit(employeeId, benefitId)
          .subscribe({
            next: benefits => {
              this.dataSource.data = this.dataSource.data.filter(item => item.id !== benefitId);
            },
            error: error => {
              console.error('Error fetching monthly benefits:', error);
            }
          });
      }
    })
  }

  onEdit(row: any) {
    if (this.editingId !== row.id) {
      this.editingId = row.id;
      this.editingFormGroup.get('amount')?.setValue(row.amount);
      this.editingFormGroup.get('subject')?.setValue(row.subject);
      this.editingFormGroup.get('currency')?.setValue(row.currency);
    }
  }

  onSubmit() {
    console.log(this.userData);
    const data = this.dataSource.data;
    data.push(this.userData);
    var benefitRequest = {
      amount: this.userData.amount.toString(),
      currency: this.userData.currency,
      company: this.userData.project,
      month: this.month,
      subject: this.userData.subject,
    };
    this.itsService.createBenefit(this.userData.name.id, benefitRequest)
      .subscribe({
        next: benefits => {
          console.log('Monthly benefits:', benefits);
        },
        error: error => {
          console.error('Error fetching monthly benefits:', error);
        }
      });
    this.dataSource.data = data;
    this.userData = <UserData>{};
  }

  getBenefits() {
    console.log('Fetching monthly benefits for:', this.month);
    this.isLoading = true;  // Set loading to true
    var data: UserData[] = [];

    this.itsService.getMonthlyBenefits(this.month)
      .subscribe({
        next: resp => {
          (resp.employees as MonthlyEmployeeBenefit[]).forEach(employee => {
            if (employee.benefits) {
                (employee.benefits as Benefit[]).forEach(b => {
                  console.log(b, employee.employee.first_name + ' ' + employee.employee.last_name);
                  data.push({
                    id: b.id,
                    employeeId: employee.employee.id,
                    name: {name: employee.employee.first_name + ' ' + employee.employee.last_name, id: employee.employee.id},
                    project: b.company,
                    subject: b.subject,
                    amount: parseInt(b.amount),
                    currency: b.currency
                  });
              });
            }
          });
          this.dataSource = new MatTableDataSource(data);
          this.isLoading = false;
        },
        error: error => {
          console.error('Error fetching monthly benefits:', error);
          this.isLoading = false;
        }
      });
  }
}
