import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../environments/environment";
import {KeycloakService} from "./keycloak.service";

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {
  private apiUrl = environment.apiUrl;

  // Injecting http client via constructor
  constructor(private http: HttpClient, private keycloakService: KeycloakService) {
  }

  // Function to send a post request along
  // with files to server
  uploadFile(files: File[]) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());
    console.log("uploadFileService");
    // Formdata to store files to send it
    // as a multipart/form-data post request
    const formData = new FormData();

    // Inserting every file to formData
    for (const file of files) {

      // Use the name as file
      // as mock backend configured
      // to get file as input in the name of file
      formData.append('file', file);
    }
    // Post method is returned for subscribing in the component
    return this.http.post(
      `${this.apiUrl}/invoice/upload`, formData, {headers});
  }
}
