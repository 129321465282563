export const MOCK_DATA = [
    {
      name: "Alice",
      project: "Project A",
      manager: "Manager X",
      currency: Math.random() < 0.5 ? "PLN" : "USD", // Randomly choose currency
      billing: Math.random() * 1000, // Random billing amount between 0 and 1000
      invoice_sum: Math.random() * 2000, // Random invoice sum between 0 and 2000
      accepted: Math.random() < 0.5, // Randomly assign accepted status
      invoice_url: "https://example.com/invoiceA.pdf"
    },
    {
      name: "Bob",
      project: "Project B",
      manager: "Manager Y",
      currency: Math.random() < 0.5 ? "PLN" : "USD",
      billing: Math.random() * 1000,
      invoice_sum: Math.random() * 2000,
      accepted: Math.random() < 0.5,
      invoice_url: "https://example.com/invoiceB.pdf"
    },
    {
      name: "Charlie",
      project: "Project C",
      manager: "Manager Z",
      currency: Math.random() < 0.5 ? "PLN" : "USD",
      billing: Math.random() * 1000,
      invoice_sum: Math.random() * 2000,
      accepted: Math.random() < 0.5,
      invoice_url: "https://example.com/invoiceC.pdf"
    },
    // Add more entries as needed
  ];