import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.guard';
import { DocumentsComponent } from './documents/documents.component';
import { TeamComponent } from './team/team.component';
import { TalentComponent } from './talent/talent.component';
import { ItsComponent } from './its/its.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { SummaryComponent} from "./summary/summary.component";
import { InvoiceUploadComponent } from "./invoice-upload/invoice-upload.component";

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard], // AuthGuardProtect the home route with AuthGuard
    children: [
      { path: 'documents', component: DocumentsComponent },
      { path: 'team', component: TeamComponent },
      { path: 'talent', component: TalentComponent },
      { path: 'its', component: ItsComponent },
      { path: 'delivery', component: DeliveryComponent },
      { path: 'summary', component: SummaryComponent },
      { path: 'upload', component: InvoiceUploadComponent },
      // other child routes for the home component
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
