import { Component, ViewChild, AfterViewInit } from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import { MOCK_DATA } from './mock.data';


export interface UserData {
  name: string;
  project: string;
  manager: string;
  currency: string;
  billing: number;
  invoice_sum: number;
  accepted: boolean;
  invoice_url: string;
}

@Component({
  selector: 'app-delivery-invoice',
  templateUrl: './delivery-invoice.component.html',
  styleUrls: ['./delivery-invoice.component.scss']
})

export class DeliveryInvoiceComponent implements AfterViewInit {
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatSort) sort: MatSort = <MatSort>{};
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};    
  displayedColumns: string[] = ['name', 'project', 'manager', 'currency', 'billing', 'invoice_sum', 'invoice', 'accept'];

  constructor() {
    this.dataSource = new MatTableDataSource(MOCK_DATA);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}


