<mat-toolbar class="menu-toolbar">
    <button mat-button class="menu-button" (click)="navigateToDocuments()" [class.active]="isDocumentsActive">
      <mat-icon>description</mat-icon>
      <span>Documents</span>
    </button>
    <button *ngIf="displayTeam()" mat-button class="menu-button" (click)="navigateToTeam()" [class.active]="isTeamActive">
      <mat-icon>psychology</mat-icon>
      <span>HR</span>
    </button>
    <button *ngIf="displayITS()" mat-button class="menu-button" (click)="navigateToITSupport()" [class.active]="isITSActive">
        <mat-icon>lan</mat-icon>
        <span>IT Support</span>
      </button>
      <button *ngIf="displayTalent()" mat-button class="menu-button" (click)="navigateToTalent()" [class.active]="isTalentActive">
        <mat-icon>groups</mat-icon>
        <span>Talent</span>
      </button>
      <button *ngIf="displayDelivery()" mat-button class="menu-button" (click)="navigateToDelivery()" [class.active]="isDeliveryActive">
        <mat-icon>contact_page</mat-icon>
        <span>Delivery</span>
      </button>
  </mat-toolbar>