import { Component, ViewChild, AfterViewInit } from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import { HrService } from '../hr.service';
import { DataService } from '../data.service';
import {MonthlyEmployeeBenefit, Benefit, UserData} from '../models';

const options = {
  BasicType: "Basic",
  EducationalType: "Educational",
  AdditionalType: "Additional",
  ITSupportType: "IT Support",
  ReferralType: "Referral"
};

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements AfterViewInit {

  month: string = "";
  dataSource: MatTableDataSource<UserData> = new MatTableDataSource<UserData>();
  @ViewChild(MatSort) sort: MatSort = <MatSort>{};
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};
  displayedColumns: string[] = ['name', 'project', 'currency', 'hourlyRate', 'benefitAmount', 'educationBonus', 'otherBonus', 'save'];

  constructor(private hrService: HrService, private dataService: DataService) {
    this.month = this.dataService.getMonth();
    this.getBenefits();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  saveBenefits(user: UserData) {
    user.edited = false;
    if (!user.currency) {
      console.info('Currency is required');
      user.currency = 'PLN';
    }

    console.log('Saving for month: ', this.month);
    user.month = this.month;

    // Check if the benefit is new or existing.
    if (user.benefitID === 0) {
      console.log('Creating new benefit for:', user);
      this.hrService.saveMonthlyBenefits(user)
        .subscribe({
          next: response => {
            console.log('Benefit successfully saved:', response);
          },
          error: error => {
            console.error('Error saving benefit:', error);
          }
        });
    } else {
      console.log('Updating benefit for:', user);
      this.hrService.updateMonthlyBenefits(user)
        .subscribe({
          next: response => {
            console.log('Benefit successfully updated:', response);
          },
          error: error => {
            console.error('Error updating benefit:', error);
          }
        });
    }

  }

  getBenefits() {
    console.log('Fetching monthly benefits for:', this.month);
    var data: UserData[] = [];
    this.hrService.getMonthlyBenefits(this.month)
      .subscribe({
        next: resp => {
          (resp.employees as MonthlyEmployeeBenefit[]).forEach(employee => {
            let emp: UserData = {
              name: { name: employee.employee.first_name + ' ' + employee.employee.last_name, id: employee.employee.id },
              project: employee.employee.project,
              benefitAmount: 0,
              currency: '',
              month: '',
              benefitID: 0,
              hourlyRate: 0,
              educationBonus: 0,
              otherBonus: 0,
              edited: false,
            }
            if (employee.benefits) {
                (employee.benefits as Benefit[]).forEach(b => {
                  emp.benefitID = b.id;
                  switch (b.type) {
                    case options.BasicType:
                      emp.benefitAmount = parseFloat(b.amount);
                      emp.currency = b.currency;
                      break;
                    case options.EducationalType:
                      emp.educationBonus = parseFloat(b.amount);
                      break;
                    case options.AdditionalType:
                      emp.otherBonus = parseFloat(b.amount);
                      break;
                  }
              });
            }
            data.push(emp);
          });
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error: error => {
          console.error('Error fetching monthly benefits:', error);
        }
      });
  }
}
