<section class="section" *ngFor="let ch of clients" style="display: inline-block;">
    <mat-checkbox class="margin" [(ngModel)]="ch.checked" (change)="propagateDataSource()">{{ch.name}}</mat-checkbox>
</section>
<table mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

    <!-- Name Column -->
    <ng-container matColumnDef="client">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Client </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.clientName}} </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef> Position </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.positionName}} </mat-cell>
    </ng-container>
    <!-- Currency Column -->
    <ng-container matColumnDef="currency">
        <mat-header-cell *matHeaderCellDef> Currency </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.currency}} </mat-cell>
    </ng-container>
    <!-- Sum Column -->
    <ng-container matColumnDef="sum">
        <mat-header-cell *matHeaderCellDef> Sum </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.sum}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>

<mat-paginator [pageSizeOptions]="[ 10, 15, 25, 100]"></mat-paginator>
<button mat-fab extended color="primary">
    <mat-icon>done_outline</mat-icon>
    Accept
</button>