import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class KeycloakService {
  private keycloakAuth: Keycloak = new Keycloak();

  constructor(private router: Router) {
    this.init()
      .then((authenticated) => {
        if (authenticated) {
          console.log('Keycloak initialized');
          this.router.navigate(['/home']); // Redirect to home page after successful login
        }
      })
      .catch(err => console.error('Keycloak initialization failed:', err));
  }

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('Initializing Keycloak...');
      this.keycloakAuth = new Keycloak({
        url: environment.KEYCLOAK_URL || '',
        realm: environment.REALM || '', // Provide a default value for realm,
        clientId: environment.CLIENT_ID || '' // Provide a default value for clientId
      });
      this.keycloakAuth.init({ onLoad: 'check-sso' })
        .then((authenticated) => {
          if (authenticated) {
            resolve(authenticated);
          } else {
            this.keycloakAuth = new Keycloak({
              url: environment.KEYCLOAK_URL || '',
              realm: environment.REALM || '', // Provide a default value for realm,
              clientId: environment.CLIENT_ID || '' // Provide a default value for clientId
            });
            console.log('Keycloak check-sso failed, trying login-required...');
            this.keycloakAuth.init({ onLoad: 'login-required' })
              .then((authenticated) => {
                resolve(authenticated);
              })
              .catch((err) => {
                reject(err);
              });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getToken(): string {
    return this.keycloakAuth.token || '';
  }

  getUserName(): string {
    return this.keycloakAuth.tokenParsed?.['preferred_username'] || '';
  }

  getUserRoles(): string[] {
    return this.keycloakAuth.tokenParsed?.['realm_access']?.roles || [];
  }

  async refreshToken(): Promise<string | undefined> {
    try {
      await this.keycloakAuth.updateToken(5);
      localStorage.setItem('keycloakAuth', JSON.stringify(this.keycloakAuth));
      return this.keycloakAuth.token;
    } catch (error) {
      console.error('Failed to refresh token:', error);
    }

    return undefined; // Add a return statement outside of the try-catch block
  }

  isLoggedIn(): boolean {
    return !!this.keycloakAuth && !!this.keycloakAuth.authenticated;
  }

  logout(): void {
    this.keycloakAuth.logout();
    this.router.navigate(['/login']); // Route to the login page
  }

}
