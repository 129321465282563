<div *ngIf="dataSource && dataSource.invoice && dataSource.summary">
  <h2>Billing Summary</h2>
  <p><strong>Status:</strong> {{dataSource.status}}</p>
  <p><strong>Currency:</strong> {{dataSource.invoice.currency}}</p>
  <p><strong>Total payment with benefits:</strong> {{dataSource.invoice.total}}</p>
   <p><strong>Total hours:</strong> {{dataSource.summary.total_hours}}</p>
  <p><strong>Rate:</strong> {{dataSource.summary.rate}}</p>
  <p><strong>Payments per:</strong> {{dataSource.summary.payments_per}}</p>
  <p><strong>Date:</strong> {{dataSource.invoice.date}}</p>
  <h3>Benefits</h3>
  <table>
    <thead>
    <tr>
      <th>Type</th>
      <th>Amount</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let benefit of dataSource.summary.benefits">
      <td>{{benefit.type}}</td>
      <td>{{benefit.amount}}</td>
    </tr>
    </tbody>
  </table>
</div>
