import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';

export interface Position {
  sum: number;
  currency: string;
  name: string;
}

export interface Client {
  name: string;
  positions: Position[];
  checked: boolean;
}

export interface TableItem {
  clientName: string;
  positionName: string;
  currency: string;
  sum: number;
}

@Component({
  selector: 'app-delivery-client',
  templateUrl: './delivery-client.component.html',
  styleUrls: ['./delivery-client.component.scss']
})

export class DeliveryClientComponent implements AfterViewInit {
  dataSource: MatTableDataSource<TableItem> = new MatTableDataSource;
  clients: Client[] = [];
  @ViewChild(MatSort) sort: MatSort = <MatSort>{};
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};
  displayedColumns: string[] = ['client', 'position', 'sum', 'currency'];

  constructor() {
    for (let i = 0; i < 4; i++) {
      var client = generateRandomClient(i)
      this.clients.push({ name: client.name, positions: client.positions, checked: true })
    }
    this.propagateDataSource();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  propagateDataSource() {
    var temp: TableItem[] = []
    this.clients.forEach((cl) => {
      if (cl.checked) {
        cl.positions.forEach((pos) => {
          const tableItem: TableItem = {
            clientName: cl.name,
            positionName: pos.name,
            currency: pos.currency,
            sum: pos.sum
          };
          temp.push(tableItem);
        })
      }
    })
    this.dataSource = new MatTableDataSource(temp)
  }
}


// Function to generate random data for a position
function generateRandomPosition(): Position {
  const currencies = ["USD", "EUR", "GBP"];
  const names = ["Position A", "Position B", "Position C"];

  return {
    sum: Math.random() * 1000, // Random sum between 0 and 1000
    currency: currencies[Math.floor(Math.random() * currencies.length)],
    name: names[Math.floor(Math.random() * names.length)]
  };
}

// Function to generate random data for a client
function generateRandomClient(index: number): Client {
  const clientNames = ["Raise", "Futurewell", "GoodRX", "RxSense"];
  const numPositions = Math.floor(Math.random() * 5) + 1; // Random number of positions between 1 and 5

  const cl: Client = {
    name: clientNames[index],
    positions: [],
    checked: true
  };

  for (let i = 0; i < numPositions; i++) {
    cl.positions.push(generateRandomPosition());
  }

  return cl;
}