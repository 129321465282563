import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {DataService} from '../data.service';
import {ReferralService} from '../referral.service';
import {MonthlyEmployeeBenefit, Benefit, UserReferralData} from '../models';


interface Option {
  name: string;
  id: number;
}

@Component({
  selector: 'app-talent',
  templateUrl: './talent.component.html',
  styleUrls: ['./talent.component.scss']
})

export class TalentComponent implements AfterViewInit {
  month: string = "";

  dataSource: MatTableDataSource<UserReferralData> = new MatTableDataSource<UserReferralData>();
  @ViewChild(MatSort) sort: MatSort = <MatSort>{};
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};
  displayedColumns: string[] = ['name', 'project', 'referred', 'period', 'amount', 'currency', 'save'];

  constructor(private referralService: ReferralService, private dataService: DataService) {
    this.month = this.dataService.getMonth();
    this.getBenefits();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getBenefits() {
    console.log('Fetching monthly benefits for:', this.month);
    var data: UserReferralData[] = [];
    this.referralService.getMonthlyReferrals(this.month)
      .subscribe({
        next: resp => {
          (resp.employees as MonthlyEmployeeBenefit[]).forEach(employee => {
            let emp: UserReferralData = {
              name: {name: employee.employee.first_name + ' ' + employee.employee.last_name, id: employee.employee.id},
              project: employee.employee.project,
              currency: '',
              period: '',
              amount: 0,
              referred: '',
              edited: false,
            }
            if (employee.benefits) {
              (employee.benefits as Benefit[]).forEach(b => {
                emp.amount = parseFloat(b.amount);
                emp.currency = 'PLN';
                emp.referred = b.subject;
                emp.period = b.start;
                emp.project = b.company;
                emp.referred = b.referred.first_name + ' ' + b.referred.last_name;
              });
            }
            data.push(emp);
          });
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error: error => {
          console.error('Error fetching monthly benefits:', error);
        }
      });
  }

  saveBenefits(user: UserReferralData) {
    user.edited = false;
    if (!user.currency) {
      console.info('Currency is required');
      user.currency = 'PLN';
    }

    user.period = this.month;
    console.log('Saving for month: ', this.month);
    console.log('Saving referral benefits for:', user);
    this.referralService.saveMonthlyBenefits(user)
      .subscribe({
        next: response => {
          console.log('Referral benefit successfully saved:', response);
        },
        error: error => {
          console.error('Error saving user:', error);
        }
      });
  }
}
