<mat-tab-group>
    <mat-tab label="Billing">
        <app-delivery-billing></app-delivery-billing>
    </mat-tab>
    <mat-tab label="Invoice">
        <app-delivery-invoice></app-delivery-invoice>
    </mat-tab>
    <mat-tab label="Client">
        <app-delivery-client></app-delivery-client>
    </mat-tab>
  </mat-tab-group>