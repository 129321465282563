import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { KeycloakService } from './keycloak.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private key: KeycloakService, private router: Router) {}

  canActivate(): boolean {
    if (this.key.isLoggedIn()) {
      return true; // Allow access if the user is logged in
    } else {
      this.key.init(); // Initialize Keycloak
      //this.router.navigate(['/login']); // Redirect to login page if not logged in
      return false;
    }
  }
}
