import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataSubject = new Subject<void>();
  private month: string = this.createDefaultMonth();

  dataObservable = this.dataSubject.asObservable();

  triggerDataReload() {
    this.dataSubject.next();
  }

  setMonth(month: string) {
    console.log("Setting month to: " + month);
    this.month = month;
    this.triggerDataReload();
  }

  getMonth() {
    return this.month;
  }

  createDefaultMonth(): string {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const formattedMonth = currentMonth.toString().padStart(2, '0');
    return `${currentYear}-${formattedMonth}`;
  }
  
}