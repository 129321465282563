<mat-stepper #stepper>
    <mat-step>
          <ng-template matStepLabel>Upload Invoice</ng-template>
          <div class="container">
            <div class="upload"></div>
            <div class="billing">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
                    <ng-container matColumnDef="subject">
                      <th mat-header-cell *matHeaderCellDef> Subject </th>
                      <td mat-cell *matCellDef="let element"> {{element.subject}} </td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef> Amount </th>
                      <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="currency">
                      <th mat-header-cell *matHeaderCellDef> Currency </th>
                      <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
              </div>
              <div class="invoice">
                <div class="center-button">
                  <button mat-fab color="primary">
                    <mat-icon>add</mat-icon>
                  </button>
                  <mat-label class="label">Upload Invoice</mat-label>
                </div>
              </div>
          </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Approval</ng-template>
        <div class="invoice">
          <div class="center-button">
            <button mat-fab color="primary">
              <mat-icon>description</mat-icon>
            </button>
            <mat-label class="label">Download Invoice</mat-label>
          </div>
        </div>
        <div>
          <mat-label class="label">Status: Waiting for Approval</mat-label>
        </div>
        <div>
          <mat-label class="label">Manager: Marcin Piesiak</mat-label>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Documents Accepted</ng-template>
    </mat-step>
  <mat-step>
    <ng-template matStepLabel>Summary</ng-template>
    <app-summary></app-summary>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Upload Invoice PDF</ng-template>
    <app-invoice-upload></app-invoice-upload>
  </mat-step>
</mat-stepper>
