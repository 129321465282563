import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

export interface PeriodicElement {
  subject: string;
  amount: number;
  currency: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {subject: 'Hourly Rate', amount: 200, currency: 'PLN'},
];

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})

export class DocumentsComponent {
  selectedPeriod: string;
  periods: string[]; // Array to hold the dropdown options

  displayedColumns: string[] = ['subject', 'amount', 'currency'];
  dataSource = ELEMENT_DATA;

  constructor(private dialog: MatDialog) {
    this.periods = this.generatePeriodOptions(); // Populate the dropdown options
    this.selectedPeriod = this.getDefaultPeriod(); // Set the default selected period
  }

  saveDocuments(): void {

  }

  generatePeriodOptions(): string[] {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // JavaScript months are zero-based
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Generate options for current month and the next two months
    const options = [];
    for (let i = 0; i < 3; i++) {
      const monthIndex = (currentMonth + i - 1) % 12;
      const year = currentYear + Math.floor((currentMonth + i - 1) / 12);
      options.push(`${months[monthIndex]} ${year}`);
    }

    return options;
  }

  getDefaultPeriod(): string {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    return `${this.getMonthName(currentMonth)} ${currentYear}`;
  }

  getMonthName(monthIndex: number): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthIndex];
  }

  onInvoiceFileSelected(event: any) {
    // Handle invoice file selection
  }

  onBillingFileSelected(event: any) {
    // Handle billing file selection
  }
}