<!-- form.component.html -->
<form (ngSubmit)="onSubmit()" #userDataForm="ngForm">
  <mat-form-field class="example-full-width">
    <input #input
           type="text"
           placeholder="Name"
           matInput
           [matAutocomplete]="auto"
           (input)="filter()"
           (focus)="filter()"
           name="name"
           [(ngModel)]="userData.name" required>
    <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">{{ option.name }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Project" name="project" [(ngModel)]="userData.project" required>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Subject" name="subject" [(ngModel)]="userData.subject" required>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Amount" type="number" name="amount" [(ngModel)]="userData.amount" required>
  </mat-form-field>

  <mat-form-field>
    <mat-select placeholder="Currency" name="currency" [(ngModel)]="userData.currency" required>
      <mat-option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button type="submit" [disabled]="userDataForm.invalid">Submit</button>
</form>

<div class="loading-overlay" *ngIf="isLoading">
  <mat-spinner diameter="40"></mat-spinner>
</div>

<!-- table.component.html -->
<table mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.name.name }}</mat-cell>
  </ng-container>

  <!-- Project Column -->
  <ng-container matColumnDef="project">
    <mat-header-cell *matHeaderCellDef> Project</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.project }}</mat-cell>
  </ng-container>

  <!-- Subject Column -->
  <ng-container matColumnDef="subject">
    <mat-header-cell *matHeaderCellDef> Subject</mat-header-cell>

    <mat-cell *matCellDef="let row">
      <span *ngIf="editingId!==row.id"> {{row.subject}} </span>
      <form *ngIf="editingId===row.id" [formGroup]="editingFormGroup">
        <mat-form-field appearance="fill" class="mat-form-field-should-float">
          <input matInput formControlName="subject"/>
        </mat-form-field>
      </form>
    </mat-cell>
  </ng-container>

  <!-- Amount Column -->
  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef> Amount</mat-header-cell>
    <mat-cell *matCellDef="let row">

    <span *ngIf="editingId!==row.id"> {{row.amount}} </span>
    <form *ngIf="editingId===row.id" [formGroup]="editingFormGroup">
      <mat-form-field appearance="fill" class="mat-form-field-should-float">
        <input matInput formControlName="amount"/>
      </mat-form-field>
    </form>
    </mat-cell>
  </ng-container>

  <!-- Currency Column -->
  <ng-container matColumnDef="currency">
    <mat-header-cell *matHeaderCellDef> Currency</mat-header-cell>

    <mat-cell *matCellDef="let row">
      <span *ngIf="editingId!==row.id"> {{row.currency}} </span>
      <form *ngIf="editingId===row.id" [formGroup]="editingFormGroup">
        <mat-form-field appearance="fill" class="mat-form-field-should-float">
          <mat-select formControlName="currency">
            <mat-option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-cell>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button mat-icon-button *ngIf="editingId===row.id" (click)="onUpdate(row)">
        <mat-icon>save</mat-icon>
      </button>
      <button mat-icon-button *ngIf="editingId!==row.id" (click)="onEdit(row)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button *ngIf="editingId===row.id" (click)="onCancelEdit()">
        <mat-icon>cancel</mat-icon>
      </button>
      <button mat-icon-button (click)="onDelete(row.employeeId, row.id)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns;" [style.display]="isLoading ? 'none' : ''"></mat-row>
</table>

<mat-paginator [pageSizeOptions]="[ 10, 15, 25, 100]"></mat-paginator>

<ng-template #deleteConfirmDialog>
  <h2 mat-dialog-title>Confirm Delete</h2>
  <mat-dialog-content>
    Are you sure you want to delete this benefit?
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="warn" [mat-dialog-close]="true">Delete</button>
  </mat-dialog-actions>
</ng-template>
