import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '../keycloak.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  constructor(private router: Router, private key: KeycloakService) { }

  navigateToDocuments(): void {
    this.router.navigate(['/home/documents']);
  }

  navigateToTeam(): void {
    this.router.navigate(['/home/team']);
  }


  navigateToITSupport(): void {
    this.router.navigate(['/home/its']);
  }

  navigateToTalent(): void {
    this.router.navigate(['/home/talent']);
  }

  navigateToDelivery(): void {
    this.router.navigate(['/home/delivery']);
  }

  get isDocumentsActive(): boolean {
    return this.router.url === '/home/documents';
  }

  get isTeamActive(): boolean {
    return this.router.url === '/home/team';
  }

  get isITSActive(): boolean {
    return this.router.url === '/home/its';
  }

  get isTalentActive(): boolean {
    return this.router.url === '/home/talent';
  }

  get isDeliveryActive(): boolean {
    return this.router.url === '/home/delivery';
  }

  displayITS(): boolean {
    if (this.key.getUserRoles().includes('manage_it_support')) {
      return true;
    }
    return false;
  }

  displayTalent(): boolean {
    if (this.key.getUserRoles().includes('manage_referral')) {
      return true;
    }
    return false;
  }

  displayDelivery(): boolean {
    if (this.key.getUserRoles().includes('manage_delivery')) {
      return true;
    }
    return false;
  }

  displayTeam(): boolean {
    if (this.key.getUserRoles().includes('manage_benefit')) {
      return true;
    }
    return false;
  }
}
