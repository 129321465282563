<!-- table.component.html -->
<table mat-table #table [dataSource]="dataSource" matSort matSortActive="employee_name" matSortDirection="asc">

    <!-- Name Column -->
    <ng-container matColumnDef="employee_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.employee_name}} </mat-cell>
    </ng-container>

    <!-- Surname Column -->
    <ng-container matColumnDef="employee_surname">
      <mat-header-cell *matHeaderCellDef> Surname </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.employee_surname}} </mat-cell>
    </ng-container>

    <!-- Rate Column -->
    <ng-container matColumnDef="rate">
        <mat-header-cell *matHeaderCellDef> Rate </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.rate}} </mat-cell>
      </ng-container>

    <!-- Currency Column -->
    <ng-container matColumnDef="currency">
      <mat-header-cell *matHeaderCellDef> Currency </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.currency}} </mat-cell>
    </ng-container>

    <!-- Total Hours Column -->
    <ng-container matColumnDef="total_hours">
      <mat-header-cell *matHeaderCellDef> Total Hours </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.total_hours}} </mat-cell>
    </ng-container>

    <!-- Total Sum Column -->
    <ng-container matColumnDef="total_sum">
        <mat-header-cell *matHeaderCellDef> Total Sum </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.total_sum}} </mat-cell>
      </ng-container>

    <!-- Month Column -->
    <ng-container matColumnDef="month">
      <mat-header-cell *matHeaderCellDef> Month </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.month}} </mat-cell>
    </ng-container>

  <!-- Origina Status Column -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef> Original Status </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.status}} </mat-cell>
  </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="new_status">
      <mat-header-cell *matHeaderCellDef> New Status </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-select [(ngModel)]="row.status" (selectionChange)="row.edited = true">
          <mat-option value="accepted">Accepted</mat-option>
          <mat-option value="denied">Denied</mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>

  <!-- Save Column -->
  <ng-container matColumnDef="save">
    <mat-header-cell *matHeaderCellDef> Save </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button mat-button *ngIf="row.edited" (click)="saveStatusForBilling(row)"><mat-icon>save</mat-icon></button>
    </mat-cell>
  </ng-container>

  <!-- Invoice filename Column -->
  <ng-container matColumnDef="invoice_path">
    <mat-header-cell *matHeaderCellDef> Invoice file </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.invoice_path}} </mat-cell>
  </ng-container>

  <!-- Download invoice Column -->
  <ng-container matColumnDef="download_invoice">
    <mat-header-cell *matHeaderCellDef> Download </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button mat-button *ngIf="row.invoice_path" (click)="downloadInvoice(row.id)"><mat-icon>download</mat-icon></button>
    </mat-cell>
  </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </table>

    <mat-paginator [pageSizeOptions]="[ 10, 15, 25, 100]"></mat-paginator>
    <button mat-fab extended color="primary">
        <mat-icon>done_outline</mat-icon>
        Accept
      </button>
