<div class="container">
  <div class="row">
    <h1>Invoice Upload Form</h1>
  </div>
  <br>
  <div class="row">
    <form (ngSubmit)="uploadFiles(this.filesElement)">
      <mat-icon>file_upload</mat-icon>
      <input class="form-element"
             name="file"
             type="file"
             (change)="changeFiles($event)"
             [multiple]="isMultiple" #filesElement />
<!--      <div class="row">-->
<!--        <mat-icon>check_box</mat-icon>-->
<!--        <input type="checkbox"-->
<!--               id="checkBox"-->
<!--               name="checkBox"-->
<!--               [(ngModel)]="isMultiple"-->
<!--               (change)="this.filesElement.value = ''" />-->
<!--        <label for="checkBox">-->
<!--          Multiple Files Upload-->
<!--        </label>-->
<!--      </div>-->
      <div class="center-button">
        <br>
        <button mat-fab color="primary" type="submit">
          <mat-icon>upload</mat-icon>
        </button>
      </div>
    </form>
  </div>
</div>
