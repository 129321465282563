import {Injectable} from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {KeycloakService} from "./keycloak.service";
import {Observable} from "rxjs";
import {MonthlyBilling} from "./models";

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {
  }

  getMonthlyBillings(): Observable<MonthlyBilling> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());
    console.log("getMonthlyBillingsService");
    return this.http.get<MonthlyBilling>(`${this.apiUrl}/delivery/billings`, {headers});

  }
}
